/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~@ionic/angular/css/ionic.bundle.css";
@import "~animate.css/animate.min.css";



.center {
    text-align: center;
}

.login-modal .modal-wrapper {
    height: 45%;
    top: 55%;
    position: absolute;
    display: block;
    border-radius: 20px 20px 0 0;
}

.register-modal .modal-wrapper {
    height: 50%;
    top: 50%;
    position: absolute;
    display: block;
    border-radius: 20px 20px 0 0;
}

.sideMenuHeader {
    .sidemenuProfile {
        border-radius: 100%;
        border: 2px solid #fff;
        padding: 3px;
    }

    .profileHeading {
        font-size: 15px;
        font-weight: 600;
    }

    .profileNotes {
        &.desg {
            font-size: 12px;
            margin-bottom: 3px !important;
        }

        &.link {
            margin-bottom: 0px !important;

            a {
                font-size: 12px;
                text-decoration: none;
                color: inherit;
            }

        }
    }
}

.sideMenuContent,
.sideMenuFooter {
    &>ion-menu-toggle {
        &>ion-list {
            &>ion-item {
                --min-height: 40px;
                font-size: 15px;
                font-weight: 500;
            }
        }
    }
}

.text-color-dark {
    color: #172b4d !important;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.hr-lines {
    position: relative;
    max-width: 500px;
    margin: 100px auto;
    text-align: center;
}

.hr-lines:before {
    content: " ";
    height: 2px;
    width: 130px;
    background: aliceblue;
    display: block;
    position: absolute;
    top: 50%;
    left: 223%;
}

.hr-lines:after {
    content: " ";
    height: 2px;
    width: 130px;
    background: aliceblue;
    display: block;
    position: absolute;
    top: 50%;
    right: 223%;
}

.loginOr {
    &.hr-lines {
        font-size: 13px;
        color: gray;
    }
}

.loginSign {
    color: #172B4E;
    font-size: 14px;
}

.forget a {
    font-size: 14px;
}

.loginPage {
    .buttonText {
        padding-left: 20px;
        color: #172B4E;
        font-size: 16px;
    }
}

.otpTextWrapper {
    display: flex;

    .otpText {
        text-align: center;
        display: flex !important;
    }
}

.primary-input {
    color: #009688;
    font-size: 14px;
    background: aliceblue;
    --padding-start: 10px !important;
    --padding-top: 8px;
    --padding-end: 10px;
    --padding-bottom: 8px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.primary-input ::placeholder {
    color: #009688 !important;
}

.ionic-selectable-has-placeholder .ionic-selectable-value-item {
    color: #009688 !important;
}

.select-text .select-placeholder {
    color: #009688 !important;
    opacity: 1;
}

ion-select {
    opacity: 1 !important;
    color: #009688 !important;
}

.primary-label {
    color: #009688;
    font-weight: 600;
    font-size: 14px;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.customRadioButton {
    &>ion-item {
        height: 30px;
        display: flex;
        align-items: center;

        &>ion-label {
            color: #5e6164 !important;
            font-weight: 600 !important;
            font-size: 13px !important;
        }

        ion-radio {
            border: 1px solid gray;
            width: 20px;
            height: 20px;
            border-radius: 10px;
        }
    }
}

.reportList {
    .back1 {
        --ion-color-base: #D0C9C0 !important;
        height: 30px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-size: 13px !important;
        --min-height: 30px;

        .labelWrap,
        .noteWrap {
            padding: 0;
            margin: 0;
            height: auto;
        }
    }

    .back2 {
        --ion-color-base: #EFEAD8 !important;
        height: 30px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-size: 13px;

        .labelWrap,
        .noteWrap {
            padding: 0;
            margin: 0;
            height: auto;
        }
    }
}

.pd-5 {
    padding: 5px !important;
}

.pd-10 {
    padding: 10px !important;
}

.card-wrap {
    display: flex;

    .block {
        display: flex;
        justify-content: space-evenly;
        width: 100%;

        ion-card {
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
            }
        }
    }
}

.profileInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.required:after {
    content: " *";
    color: red;
}

.groupWrap {
    .inputGroupWrap {
        display: inline-block;
        width: 50%;
        border-radius: 0;

        ::placeholder {
            color: white !important;
        }

        &:first-child {
            border-right: 1px solid #646668;
        }
    }

    .inputGroupWrap-1 {
        display: inline-block;
        width: 60%;
        border-radius: 0;

        ::placeholder {
            color: white !important;
        }

        &:first-child {
            border-right: 1px solid #646668;
        }
    }

    .inputGroupWrap-2 {
        display: inline-block;
        width: 40%;
        border-radius: 0;

        ::placeholder {
            color: white !important;
        }

        &:first-child {
            border-right: 1px solid #646668;
        }
    }
}

.profileSetttings {
    .sideMenuHeader {
        &.profile {
            display: flex;
            align-items: center;
            justify-content: center;

            .sideMenuHeader {
                position: relative;

                .sidemenuProfile {
                    border: 2px solid #84828f;
                    width: 10rem;
                    height: 10rem;
                }

                .iconBack {
                    width: 2rem;
                    position: absolute;
                    border-radius: 50%;
                    right: -0.2rem;
                    top: 2rem;
                    background-color: #84828f;
                    height: 2rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .cameraIconWrap {
                        color: #322f31;
                        width: 1.4rem;
                        height: 1.4rem;
                    }
                }
            }
        }
    }

    .primary-label {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .iconBack {
            &>.cameraIconWrap {
                color: #322f31;
                width: 1.3rem;
                height: 1.3rem;
            }
        }
    }
}

@keyframes bounce-top {
    0% {
        transform: translateY(-45px);
        animation-timing-function: ease-in;
        opacity: 1
    }

    24% {
        opacity: 1
    }

    40% {
        transform: translateY(-24px);
        animation-timing-function: ease-in
    }

    65% {
        transform: translateY(-12px);
        animation-timing-function: ease-in
    }

    82% {
        transform: translateY(-6px);
        animation-timing-function: ease-in
    }

    93% {
        transform: translateY(-4px);
        animation-timing-function: ease-in
    }

    25%,
    55%,
    75%,
    87% {
        transform: translateY(0);
        animation-timing-function: ease-out
    }

    100% {
        transform: translateY(0);
        animation-timing-function: ease-out;
        opacity: 1
    }
}

.topImage {
    animation: bounce-top 1s both;

}


.toast-danger {
    --background: #eb445a !important;
    --border-radius: 2px !important;
}

.toast-success {
    --background: #52f811 !important;
    --border-radius: 2px !important;
}

.number-input {
    text-align: right;
}

.autocomplete-container {
    box-shadow: none !important;
    height: 32px !important;
    border: 0px solid red;
    border-radius: 5px;

    .input-container {
        ::placeholder {
            color: #939393;
        }

        input {
            font-size: 14px;
            background-color: aliceblue !important;
            color: #009688 !important;
            width: 100% !important;
            padding: 2px 15px;
            line-height: 40px !important;
            height: 34px !important;

            border-radius: 5px;
            box-sizing: border-box !important;

        }

        input ::placeholder {
            color: #009688 !important;
        }
    }
}

.datetime-size-fixed {
    max-width: 100%;
    display: block;
    min-height: auto;
}

.modal-fullscreen {
    --height: calc(100% - (var(--ion-safe-area-top) + -150px));
}


.buttonload {
    /* Green background */
    border: none;
    /* Remove borders */
    color: #ffffff;
    /* White text */
    padding: 12px 24px;
    /* Some padding */
    font-size: 16px;
    /* Set a font-size */
}

/* Add a right margin to each icon */
.fa {
    margin-left: -12px;
    margin-right: 8px;
    color: #ffffff;
}

ion-toolbar {
    --color: #ffffff;
    --background: darkcyan;
}

.native-input[disabled].sc-ion-input-md {
    opacity: 1;
}

.cardList {
    border: 2px solid darkcyan !important;
}

.sbt {
    --background: #10847E !important;
}

ion-fab-button {
    // background: #f50b0bbd !important;
    margin-bottom: 10px !important;
    --color: #ffffff !important;
}

.content {
    overflow-y: scroll;
    max-height: 90%;
}

.mt {
    margin-top: 3px;
    margin-bottom: 3px;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.ionic-selectable {
    display: block;
    max-width: 100% !important;
    height: 45px;
}

.cls {
    color: darkcyan !important;
}

.location {
    --border: none;
    --background: transparent;
    --background-activated: transparent;
    --background-focused: currentColor;
    --background-hover: currentColor;
    color: #009688;
    margin-top: 20px;
}

table {
    overflow: inherit !important;
    z-index: 10px;
    width: 100%;
    font-size: 0.875rem !important;
    letter-spacing: 0.01071em;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }
  
  table thead {
    background: #90caf9;
    color: #000000;
    line-height: 1.5rem;
  }
  
  table thead th {
    position: -webkit-sticky;
    position: sticky;
    background: #90caf9;
    color: #000000;
    line-height: 1.5rem;
    top: 0;
    font-size: 0.875rem !important;
    text-align: left;
    padding: 16px 2px;
    border-bottom: solid 1px #e2e2e2;
    letter-spacing: 0.01071em;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }
  
  table td {
    color: darkcyan;
    text-align: left;
    padding: 16px 2px;
    border-bottom: solid 1px #e2e2e2;
    letter-spacing: 0.01071em;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }
  
  //table th:first-child, table td:first-child{border-left: solid 1px #e2e2e2;}
  //table th{font-weight:700;}
  table td dropdown:hover .dropdown-content {
    position: relative !important;
    /* Sometimes needs !important */
  }
  
  table th,
  table td {
    text-align: center !important;
  }